
import {defineAsyncComponent, defineComponent, ref, watch} from "vue";
import { useFoldersStore } from "@/stores/folders";
import api from "@/api/spaceMountainAPI";
import {AxiosResponse} from "axios";
import {storeToRefs} from "pinia";

export default defineComponent({
  name: "UploadFile",
  components: {
    Modale: defineAsyncComponent(() => import('@/modales/Modale.vue')),
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
  },
  props: {
    isOpen: { type: Boolean, default: true },
    folderIds: { type: Array, default: () => [] },
    formId: { type: String, default: null }
  },
  setup(props, {emit}) {
    //STORE folder
    const foldersStore = useFoldersStore();
    const { folderLoader } = storeToRefs(foldersStore);

    //DATA
    const files = ref<Array<any>>([]);

    const loadFile = (filetmp: any) => {
      files.value = [];
      for (let i = 0; i < filetmp.target.files.length; i++) {
        let reader = new FileReader();
        reader.readAsDataURL(filetmp.target.files[i]);
        reader.onload = () => {
          files.value.push({ name: filetmp.target.files[i].name, b64: reader.result });
        };
      }
    };

    watch(files, (test) => {
      console.log('files', test);
    });

    const uploadFile = async () => {
      console.log('files', files.value, props.folderIds);
      files.value.forEach((item) => console.log(item.name));
      for (const file of files.value) {
        await props.folderIds.forEach(async (folderId) => {
          await foldersStore.uploadFile(file.b64, file.name, folderId as string, props.formId);
        });
      }
      emit('uploadFile', files.value);
    };

    return { loadFile, uploadFile, folderLoader, files };
  },
});
